import React from "react"
import { Pagination } from "antd"
import SEO from "../../components/seo"
import { Link, graphql } from "gatsby"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
import typography, { rhythm, scale } from "../../utils/typography"
import { itemListAbout } from "../../utils/sidebar/item-list"
// import Button from "../../components/button"
import presets, { colors } from "../../utils/presets"
import Container from "../../components/container"

class NewsPage extends React.Component {

  state = {
    newsList: [],
    pageSize: 10,
    currentPage: 1
  }

  componentDidMount() {
    const { edges } = this.props.data.allMarkdownRemark
    if (edges.length) {
      const start = (this.state.currentPage - 1) * this.state.pageSize, end = this.state.currentPage * this.state.pageSize;
      const newsList = edges.slice(start, end);
      this.setState({ newsList });
    }
  }

  changeNewsList = (page) => {
    const { edges } = this.props.data.allMarkdownRemark
    const start = (page - 1) * this.state.pageSize, end = page * this.state.pageSize;
    const newsList = edges.slice(start, end);
    this.setState({newsList, currentPage: page});
  }

  render() {
    const { totalCount } = this.props.data.allMarkdownRemark
    const { newsList, pageSize, currentPage } = this.state
    return (
        <Layout location={this.props.location} itemList={itemListAbout} contentTitle="游龙网网络大事记">
            <SEO title="游龙网大事记" />
            {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
                <h2 css={styles.contentTitle}>
                    <span>
                        游龙网大事记
                    </span>
                </h2>
            </div> */}
            <Container overrideCSS={{maxWidth: '100%', padding: 24, minHeight: 'calc(100vh - 383px)'}}>
                <h4 css={styles.newsTitle}>共{totalCount} 条新闻</h4>
                {newsList.map(({ node }) => (
                  <div key={node.id}>
                      <Link to={node.fields.slug} css={{textDecoration: `none`, color: `inherit`}}>
                        <h4 css={{ fontSize: 14, lineHeight: '21px'}}>
                            {node.frontmatter.title}{" "}
                            <span css={{color:` #bbb`, fontWeight: 500}}>
                            — {node.frontmatter.date}
                            </span>
                        </h4>
                      </Link>
                  </div>
                ))}
                <div css={{ marginTop: 24, fontWeight: 600 }}>
                  <Pagination
                    hideOnSinglePage={false}
                    total={totalCount}
                    pageSize={pageSize}
                    current={currentPage}
                    onChange={this.changeNewsList}
                    showTotal={(total) => `共 ${Math.ceil(total / pageSize)} 页, 当前第 ${currentPage} 页`}
                  />
                </div>
            </Container>
            {/* <div css={styles.footer}>
              <Footer />
            </div> */}
        </Layout>
    )
  }

}

const styles = {
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 1,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
        position: `sticky`,
        top: `calc(${presets.bannerHeight} - 1px)`,
        [presets.Desktop]: {
            top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
        },
    },
    summaryTitle: {
      margin: `0 0 10px 0`,
      padding: '0 15px 15px 15px',
      alignItems: `center`,
      borderBottom: `1px solid ${colors.ui.border}`,
      display: `flex`,
      flexDirection: `row`,
      zIndex: 1,
    },
    newsTitle: {
      marginBottom: 18
    }
}

export default NewsPage

export const query = graphql`
    query {
        allMarkdownRemark(filter: { frontmatter: { type: { eq: "about-news"} } } sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
